
import { defineComponent } from "vue";
import { IonHeader, IonGrid, IonRow, IonCol } from "@ionic/vue";
import BudgetField from "@/components/BudgetField.vue";

export default defineComponent({
    name: "CustomHeader",
    components: {
        IonHeader,
        IonGrid,
        IonCol,
        IonRow,
        BudgetField
    }
});
