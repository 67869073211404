import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "ion-text-left ion-text-wrap",
  style: {"padding-bottom":"10px","padding-top":"10px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!

  return (_openBlock(), _createBlock(_component_ion_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_col, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, { style: {"width":"100%"} }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                return (_openBlock(), _createBlock(_component_ion_item, {
                  style: {"--padding-start":"0","--inner-padding-end":"0"},
                  lines: "none",
                  key: item.id
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.itemSelected[_ctx.itemSelected.length - 1]) + " ", 1),
                    _createVNode(_component_base_button, {
                      "single-select": "",
                      onClick: ($event: any) => (_ctx.selectItem(item)),
                      color: (_ctx.nextActive && _ctx.itemSelected.id === item.id) ? 'primary' : 'light',
                      class: "select-button",
                      style: {"height":"100%","padding-bottom":"9px","padding-top":"9px"}
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_1, _toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick", "color"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}