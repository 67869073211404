import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/Home.vue'
import { useStore } from '@/store/pinia';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'Home',
        component: HomePage,
    },
    {
        path: '/node/',
        name: 'Slider',
        component: HomePage
    },
    {
        path: '/node/:nodeid',
        meta: {
            noEntry: true
        },
        name: 'Node',
        component: HomePage
    },
    {
        path: '/finish',
        name: 'EndPage',
        meta: {
            noEntry: true
        },
        component: HomePage
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from) => {
    //route guard
    const store = useStore()
    const toNode = (to.params?.nodeid || '') as string
    const fromNode = (from.params?.nodeid || '') as string
    const nextNode = store.history[toNode]?.config.nodes[toNode].next ?? ''

    //for jumping back to nodes via browser
    if (Object.keys(store.history).length && store.history[nextNode]) {
        delete store.history[fromNode]
    }

    //for reloading
    if ((from.path === '/' || from.path === '/home') && to.meta?.noEntry) {
        return router.replace({ name: 'Home' })
    }

    // for jumping forward via browser
    if (!store.history[toNode] && to.name === 'Node') {
        return router.replace(from)
    }
})

export default router
