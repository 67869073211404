
import { defineComponent, PropType } from "vue";
import { NODE_TYPE } from "@/types/typeDefinitions";
import SliderNode from "@/components/node/nodeTypes/Slider.vue";
import SingleSelect from "@/components/node/nodeTypes/SingleSelect.vue"
import MultipleSelect from "@/components/node/nodeTypes/MultipleSelect.vue";
import QuestionSelect from "@/components/node/nodeTypes/QuestionSelect.vue";
import EndPage from "@/components/node/nodeTypes/EndPage.vue";

export default defineComponent({
    name: "NodeContent",
    props: {
        nodeType: String as PropType<NODE_TYPE>,
        items: {
            required: true,
            type: Array
        },
        nextActive: Boolean
    },
    emits: ["nextActive", "nextInactive"],
    components: {
        QuestionSelect,
        MultipleSelect,
        SliderNode,
        SingleSelect,
        EndPage,
    },
    setup() {
        return {
            NODE_TYPE
        }
    }
})
