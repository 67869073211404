<template>
    <h1 class="ion-text-center headline">
        {{ headlineText }}
    </h1>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "NodeHeadline",
    props: {
        headlineText: String,
    }
})
</script>

<style scoped>
.headline {
    font-size: 36px;
    color: var(--ion-headline-color);
    font-weight: 500;
}
</style>
