import Axios, { AxiosInstance } from "axios";
import { useStore } from "@/store/pinia";
import { PiniaStore, State } from "@/types/typeDefinitions";

export const API_URL = process.env.VUE_APP_API_URL


export function useApi() {
    const api: AxiosInstance = Axios.create({
        baseURL: API_URL,
    });
    const store = useStore()

    const getPiniaStore = async (): Promise<PiniaStore | undefined> => {
        const response = await api.get('api/config').catch((error) => {
            console.log(error);
            return undefined
        })
        if (!response) return undefined
        return response.data
    }

    const sendHistory = async (state: State = State.node): Promise<boolean> => {
        const response = await api.post('api/history', {
            ...store.data,
            state: state,
            localId: store.localId,
            source: store.source,
            userAgent: store.getUserAgent(),
        }).catch((error) => {
            console.log(error);
            return undefined
        })
        if (!response) return false
        return true
    }

    const sendCommit = async (): Promise<boolean> => {
        const data = store.data
        data.contactInfo.email = data.contactInfo.email?.trim()
        data.contactInfo.tel = data.contactInfo.tel?.trim()
        data.contactInfo.name = data.contactInfo.name?.trim()
        data.contactInfo.company = data.contactInfo.company?.trim()
        data.contactInfo.message = data.contactInfo.message?.trim()

        const response = await api.post('api/commit', data).catch((error) => {
            console.log(error);
            return undefined
        })
        if (!response) return false
        return true
    }

    return {
        api,
        sendHistory,
        getPiniaStore,
        sendCommit
    }
}
