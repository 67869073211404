
import { computed, defineComponent, getCurrentInstance } from "vue";
import { IonButton, IonCol, IonInput, IonItem, IonLabel, IonRow, IonTextarea } from "@ionic/vue";
import { useApi } from "@/composables/api";
import { useStore } from "@/store/pinia";

export default defineComponent({
    name: "ContactForm",
    components: {
        IonRow,
        IonCol,
        IonButton,
        IonInput,
        IonItem,
        IonLabel,
        IonTextarea,
    },
    emits: ["commitSent"],
    setup() {
        const store = useStore()
        const { sendCommit } = useApi()
        const { emit, ...context } = getCurrentInstance() as NonNullable<ReturnType<typeof getCurrentInstance>>;

        const sendRequest = async () => {
            const response = await sendCommit()
            if (response) emit.call(context, 'commitSent')
        }

        const emailValid = computed(() => {
            const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            return regex.test(store.data.contactInfo.email?.trim());
        })

        const buttonActive = computed(() => {
            return !!(store.data.contactInfo.name?.trim() && emailValid.value);
        })

        return {
            sendRequest,
            buttonActive,
            store,
        }
    }
})
