import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_component_ion_button, {
    class: _normalizeClass({'no-shadow': _ctx.noShadow, 'next-button': _ctx.nextButton, 'single-select': _ctx.singleSelect}),
    color: _ctx.color,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click-button', $event)))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class", "color"]))
}