import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_node_headline = _resolveComponent("node-headline")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_node_description = _resolveComponent("node-description")!
  const _component_node_content = _resolveComponent("node-content")!
  const _component_node_pagination = _resolveComponent("node-pagination")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_ion_grid, {
            style: _normalizeStyle((_ctx.$route.name === 'Node') ? 'padding-top: 160px' : 'padding-top: 80px;')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    "size-lg": "2",
                    size: "0"
                  }),
                  _createVNode(_component_ion_col, {
                    "size-lg": "8",
                    size: "12",
                    style: {"padding":"0 10px"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_node_headline, {
                                "headline-text": _ctx.node.name
                              }, null, 8, ["headline-text"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_node_description, {
                                "description-text": _ctx.node.description
                              }, null, 8, ["description-text"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              (_ctx.node.type)
                                ? (_openBlock(), _createBlock(_component_node_content, {
                                    key: 0,
                                    "next-active": _ctx.nextActive,
                                    onNextInactive: _cache[0] || (_cache[0] = ($event: any) => (_ctx.nextActive = false)),
                                    onNextActive: _cache[1] || (_cache[1] = ($event: any) => (_ctx.nextActive = true)),
                                    items: _ctx.node.options,
                                    "node-type": _ctx.node.type
                                  }, null, 8, ["next-active", "items", "node-type"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, { style: {"padding-top":"30px"} }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              (_ctx.$route.name !== 'EndPage')
                                ? (_openBlock(), _createBlock(_component_node_pagination, {
                                    key: 0,
                                    onNextInactive: _cache[2] || (_cache[2] = ($event: any) => (_ctx.nextActive = false)),
                                    "next-active": _ctx.nextActive,
                                    onNext: _ctx.nextNode,
                                    onSecondPage: _ctx.openSecond,
                                    "is-Node": _ctx.isNode
                                  }, null, 8, ["next-active", "onNext", "onSecondPage", "is-Node"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    "size-lg": "2",
                    size: "0"
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["style"]), [
            [_vShow, !_ctx.isLoading]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}