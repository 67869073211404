import { useStore } from "@/store/pinia";
import { useApi } from "@/composables/api";

export async function initPiniaStore() {
    const store = useStore()
    const { getPiniaStore } = useApi()

    const response = await getPiniaStore()
    if (response) {
        store.data = response
    }
}
