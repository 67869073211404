import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_base_slider = _resolveComponent("base-slider")!
  const _component_ion_row = _resolveComponent("ion-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_col, {
          size: "0",
          "size-sm": "1",
          "size-md": "2"
        }),
        _createVNode(_component_ion_col, null, {
          default: _withCtx(() => [
            _createVNode(_component_base_slider, {
              modelValue: _ctx.store.data.budget,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.store.data.budget) = $event)),
              min: _ctx.min,
              max: _ctx.max,
              "format-pin": _ctx.formatPin
            }, {
              "max-slot": _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.max/1000) + "k+ €", 1)
              ]),
              "min-slot": _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.min/1000) + " €", 1)
              ]),
              _: 1
            }, 8, ["modelValue", "min", "max", "format-pin"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_col, {
          size: "0",
          "size-sm": "1",
          "size-md": "2"
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_row, { style: {"height":"20px"} }, {
      default: _withCtx(() => [
        (_ctx.store.data.budget)
          ? (_openBlock(), _createBlock(_component_ion_col, {
              key: 0,
              class: "center"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(new Intl.NumberFormat('de-DE', { style: 'decimal' }).format(_ctx.store.data.budget)) + _toDisplayString(_ctx.store.data.budget < _ctx.max ? ' €' : '+ €'), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}