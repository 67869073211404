
import { computed, defineComponent } from "vue";
import { IonCol, IonGrid, IonRow } from "@ionic/vue";
import { useStore } from "@/store/pinia";
import BudgetSmall from "@/components/slider/BudgetSmall.vue";
import BudgetBig from "@/components/slider/BudgetBig.vue";

export default defineComponent({
    name: "BudgetField",
    components: {
        IonGrid,
        IonRow,
        IonCol,
        BudgetSmall,
        BudgetBig
    },
    setup() {
        const store = useStore()
        const min = store.data.config.startBudgetLow
        const max = computed(() => {
            if(store.data.budget > store.data.calcBudgetHigh) return Math.round((store.data.budget + 5000) / 5000) * 5000
            return Math.round((store.data.calcBudgetHigh + 5000) / 5000) * 5000
        })
        const budget = store.data.budget
        // const calcBudgetLow = store.data.calcBudgetLow
        // const calcBudgetHigh = store.data.calcBudgetHigh
        const formatPin = (value: number) => {
            return `${value / 1000} k`
        }

        const calcPercentageLow = () => {
            const percentage = (store.data.calcBudgetLow - store.data.config.startBudgetLow) / (max.value - store.data.config.startBudgetLow) * 100
            return checkValidPercentage(percentage)
        }

        const calcPercentageHigh = () => {
            const percentage = (store.data.calcBudgetHigh - store.data.config.startBudgetLow) / (max.value - store.data.config.startBudgetLow) * 100
            return checkValidPercentage(percentage)
        }

        const calcPercentageWish = () => {
            const percentage = (store.data.budget - store.data.config.startBudgetLow) / (max.value - store.data.config.startBudgetLow) * 100
            return checkValidPercentage(percentage)
        }

        const checkValidPercentage = (percentage: number) => {
            if (percentage < 0) {
                return 0
            } else if(percentage > 100){
                return 100
            }
            return percentage
        }
        return {
            formatPin,
            calcPercentageHigh,
            calcPercentageWish,
            store,
            min,
            max,
            budget,
            calcPercentageLow,
            // calcBudgetLow,
            // calcBudgetHigh
        }
    }
})
