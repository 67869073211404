interface Calculator {
    startNode: NodeId,
    nodes: {
        [key: NodeId]: Node
    },
    startBudgetLow: number,
    startBudgetHigh: number
}

interface Node {
    next: NodeId | null,
    options: Option[]
    isActive: boolean,
    name: string,
    description: string,
    type: NODE_TYPE | null
}

interface Option {
    id: OptionId,
    name: string,
    enable: NodeId[],
    calculationInfo: CalculationInfo,
    infoText: string,
    options: Option[]
}

interface CalculationInfo {
    addLow: number, // += addLow
    addHigh: number, // += addHigh
    multiplyLow: number, // += multiplyLow
    multiplyHigh: number // += multiplyHigh
}

enum NODE_TYPE {
    SLIDER = 'SLIDER',
    MULTIPLE_SELECT = 'MULTIPLE_SELECT',
    SINGLE_SELECT = 'SINGLE_SELECT',
    QUESTION = 'QUESTION',
    START_PAGE = 'START_PAGE',
    END_PAGE = 'END_PAGE'
}

type NodeId = string; // uuid
type OptionId = string; // uuid
type UserId = string; // uuid
type Budget = number;

interface Answer {
    [key: NodeId]: Choice
}

interface PiniaStore {
    userId: UserId,
    config: Calculator,
    budget: number,
    answers: Answer,
    // history: NodeId[],
    contactInfo: ContactInfo
    calcBudgetLow: number,
    calcBudgetHigh: number,
    calcBudgetAddLow: number,
    calcBudgetAddHigh: number,
    calcBudgetMultiplyLow: number,
    calcBudgetMultiplyHigh: number
}

interface Choice {
    [key: OptionId]: {
        info: string,
        calculationInfo: CalculationInfo,
        choice?: Choice
    }
}

class ContactInfo {
    constructor(
        public name: string = '',
        public email: string = '',
        public tel: string = '',
        public company: string = '',
        public message: string = ''
    ) {
    }
}

interface StoreHistory {
    [key: NodeId]: PiniaStore
}

enum State {
    init = 'init',
    start = 'start',
    budget = 'budget',
    node = 'node',
    end = 'end'
}

export {
    Calculator,
    Node,
    Option,
    PiniaStore,
    Choice,
    ContactInfo,
    Answer,
    NODE_TYPE,
    NodeId,
    OptionId,
    UserId,
    Budget,
    CalculationInfo,
    StoreHistory,
    State
}
