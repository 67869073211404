
import { defineComponent, getCurrentInstance, onMounted, PropType, reactive } from "vue";
import { IonCol, IonRow } from "@ionic/vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import { useCalculation } from "@/composables/calculation";
import { Option } from "@/types/typeDefinitions";
import { nodeHandling } from "@/composables/nodeHandling";

export default defineComponent({
    name: "QuestionSelect",
    components: {
        BaseButton,
        IonRow,
        IonCol
    },
    props: {
        items: {
            required: true,
            type: Array as PropType<Option[]>
        }
    },
    emits: ["nextActive"],
    setup(props) {
        const { calcBudget } = useCalculation()
        const { saveAnswers } = nodeHandling()
        const optionsSelected = reactive<{ [key: string]: Option | any }>({})
        const { emit, ...context } = getCurrentInstance() as NonNullable<ReturnType<typeof getCurrentInstance>>;

        onMounted(() => {
            for (const item of props.items) {
                optionsSelected[item.id] = {}
            }
        })

        const selectItem = (item: Option, option: Option) => {
            saveAnswers(item, option)
            calcBudget(option, optionsSelected[item.id])

            optionsSelected[item.id] = option

            let flag = true
            for (const key in optionsSelected) {
                if (!optionsSelected[key]?.id) flag = false
            }
            if (flag) emit.call(context, 'nextActive')
        }
        return {
            selectItem,
            optionsSelected
        }
    }
})
