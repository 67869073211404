
import { defineComponent } from "vue";
import { useStore } from "@/store/pinia";
import { IonCol, IonRow } from "@ionic/vue";

export default defineComponent({
    name: "BudgetSmall",
    components: {
        IonCol,
        IonRow,
    },
    setup() {
        return {
            store: useStore()
        }
    }
})
