import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ion-text-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_ion_row = _resolveComponent("ion-row")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
    return (_openBlock(), _createBlock(_component_ion_row, {
      key: item.id
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_col, { class: "center-vert" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.name), 1)
          ]),
          _: 2
        }, 1024),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options, (option) => {
          return (_openBlock(), _createBlock(_component_ion_col, {
            size: "12",
            "size-sm": "auto",
            key: option.id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_base_button, {
                onClick: ($event: any) => (_ctx.selectItem(item, option)),
                color: (_ctx.optionsSelected[item.id] !== option) ? 'light' : 'primary',
                class: "select-button"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(option.name), 1)
                ]),
                _: 2
              }, 1032, ["onClick", "color"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 2
    }, 1024))
  }), 128))
}