<template>
    <ion-range style="font-size: 9px;"
               :min="min" :max="max" step="1000"
               :value="value"
               @change="$emit('input', $event)"
               pin :pin-formatter="formatPin">
        <ion-label style="font-size: 16px;" slot="start">
            <slot name="min-slot"/>
        </ion-label>
        <ion-label style="font-size: 16px;" slot="end">
            <slot name="max-slot"/>
        </ion-label>
    </ion-range>
</template>

<script>
import { defineComponent } from "vue";
import { IonLabel, IonRange } from "@ionic/vue";

export default defineComponent({
    name: "BaseSlider",
    components: {
        IonRange,
        IonLabel,
    },
    props: {
        min: {
            required: true,
            type: Number
        },
        max: {
            required: true,
            type: Number,
        },
        formatPin: Function,
        value: Number
    }
})
</script>

<style scoped>

</style>
