import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_header = _resolveComponent("custom-header")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_page = _resolveComponent("ion-page")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_page, null, {
        default: _withCtx(() => [
          _createVNode(_component_custom_header, { class: "ion-no-border" }),
          _createVNode(_component_ion_router_outlet, {
            animated: "false",
            style: {"margin-top":"var(--header-height)"}
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}