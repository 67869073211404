import { useStore } from "@/store/pinia";
import { Choice, NodeId, Option } from "@/types/typeDefinitions";
import router from "@/router";

export function nodeHandling() {

    const store = useStore()

    const setActive = (answerObject: Option) => {
        answerObject.enable.forEach((value: NodeId) => {
            store.data.config.nodes[value].isActive = true
        })
    }

    const enableNodes = async (nodeId: string): Promise<void> => {
        for (const optionId in store.data.answers[nodeId]) {
            const option = store.data.config.nodes[nodeId].options.find((option: Option) => option.id === optionId)
            if (option) {
                if (option.options.length) {
                    //TYPE QUESTION
                    for (const choiceId in store.data.answers[nodeId][optionId].choice) {
                        const choice = option.options.find((choice: Option) => choice.id === choiceId)
                        if (choice) {
                            setActive(choice)
                        }
                    }
                } else {
                    //NOT TYPE QUESTION
                    setActive(option)
                }
            }
        }
    }

    const saveAnswers = (item: Option, option?: Option) => {
        const nodeId = router.currentRoute.value.params.nodeid as string
        console.log(item)
        const answer = {
            info: item.name,
            calculationInfo: item.calculationInfo,
            choice: {} as Choice
        }
        console.log(answer)
        if (option) {
            answer.choice[option.id] = {
                info: option.name,
                calculationInfo: option.calculationInfo,
            }
        }

        if (!store.data.answers[nodeId]) {
            store.data.answers[nodeId] = {}
        }

        store.data.answers[nodeId][item.id] = answer
    }

    const deleteAnswers = (item: Option /*, option?: Option*/) => {
        const nodeId = router.currentRoute.value.params.nodeid as string

        if (store.data.answers[nodeId]?.[item.id]) delete store.data.answers[nodeId][item.id]
    }

    return {
        enableNodes,
        saveAnswers,
        deleteAnswers,
    }
}
