
import { defineComponent } from "vue";
import { IonCol, IonRow } from "@ionic/vue";
import { useStore } from "@/store/pinia";
import BaseSlider from "@/components/slider/BaseSlider.vue";

export default defineComponent({
    name: "SliderNode",
    components: {
        BaseSlider,
        IonRow,
        IonCol
    },
    setup() {
        const store = useStore()
        const min = store.data.config.startBudgetLow
        const max = store.data.config.startBudgetHigh
        const formatPin = (value: number) => {
            return `${value/1000} k`
        }
        return {
            formatPin,
            store,
            min,
            max,
        }
    }
})
