<template>
    <div class="ion-text-center">
        {{ descriptionText }}
    </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "NodeDescription",
    props: {
        descriptionText: String,
    }
})
</script>

<style scoped>

</style>
