import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b2ba644a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "ion-text-left ion-text-wrap",
  style: {"padding-bottom":"10px","padding-top":"10px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!

  return (_openBlock(), _createBlock(_component_ion_row, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_ion_col, {
          style: {"position":"relative"},
          size: "12",
          "size-md": "6",
          "size-xl": "4",
          key: item.id
        }, {
          default: _withCtx(() => [
            _createVNode(_component_base_button, {
              onClick: ($event: any) => (_ctx.selectItem(item)),
              color: (_ctx.itemsSelected.includes(item)) ? 'primary' : 'light',
              class: "select-button button-text-left button-padding-end",
              "show-info": item.infoText,
              style: {"height":"100%","padding-bottom":"4px","padding-top":"4px"}
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_1, _toDisplayString(item.name), 1)
              ]),
              _: 2
            }, 1032, ["onClick", "color", "show-info"]),
            (item.infoText)
              ? (_openBlock(), _createBlock(_component_ion_icon, {
                  key: 0,
                  style: _normalizeStyle([(_ctx.itemsSelected.includes(item)) ? 'color: var(--ion-color-light)' : 'color: var(--ion-text-color)', {"font-size":"22px"}]),
                  onClick: ($event: any) => (_ctx.showPopover($event, item.infoText)),
                  class: "info-icon cursor-pointer",
                  icon: _ctx.helpCircleOutline
                }, null, 8, ["style", "onClick", "icon"]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}