import { defineStore } from 'pinia'
import { Answer, ContactInfo, NODE_TYPE, PiniaStore, StoreHistory } from "@/types/typeDefinitions";
import { v4 as uuid4 } from 'uuid';
import { Preferences } from '@capacitor/preferences';

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useStore = defineStore('main', {
    // other options...
    state: () => {
        const homePage = {
            name: 'Was kostet meine App?',
            description: 'Basierend auf dem Aufwand den wir aus Ihren angegebenen Informationen berechnen, berechnet sich Ihre Preiskalkulation. Selbstverständlich sind angegebene Preise lediglich eine Schätzung und können in der finalen Entwicklung basierend auf mehr Informationen anders ausfallen.',
            // description: 'Beantworten Sie uns die folgenden Fragen und wir berechnen basierend auf dem Aufwand den wir aus Ihren angegebenen Informationen, eine erste unverbindliche Schätzung für ihre Preiskalkulation.',
            options: [],
            isActive: true,
            type: NODE_TYPE.START_PAGE,
            next: ''
        }

        const sliderPage = {
            name: 'Ihr Budget',
            description: 'Vielleicht haben Sie sich schon Gedanken zu Ihrem geplanten Budget gemacht? Helfen Sie uns indem Sie dieses über den folgenden Slider angeben:',
            options: ['slider'],
            isActive: true,
            type: NODE_TYPE.SLIDER,
            next: ''
        }

        const endPage = {
            name: 'Fertig!',
            description: 'Vielen Dank, dass Sie sich die Zeit genommen haben unsere Fragen zu beantworten!',
            options: [],
            isActive: true,
            type: NODE_TYPE.END_PAGE,
            next: ''
        }

        const data = {
            config: {
                startNode: '01',
                nodes: {
                    '01': {
                        next: '03',
                        options: [
                            {
                                id: '00001',
                                name: 'App Android',
                                enable: [],
                                calculationInfo: {
                                    addLow: 100,
                                    addHigh: 200,
                                    multiplyLow: 0.25,
                                    multiplyHigh: 0.35
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00002',
                                name: 'App iOS (Apple)',
                                enable: [],
                                calculationInfo: {
                                    addLow: 100,
                                    addHigh: 200,
                                    multiplyLow: 0.25,
                                    multiplyHigh: 0.35
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00003',
                                name: 'Webanwendung',
                                enable: [],
                                calculationInfo: {
                                    addLow: 100,
                                    addHigh: 200,
                                    multiplyLow: 0.1,
                                    multiplyHigh: 0.15
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00004',
                                name: 'Desktop App (Windows, Linux, MacOS)',
                                enable: [],
                                calculationInfo: {
                                    addLow: 100,
                                    addHigh: 200,
                                    multiplyLow: 0.25,
                                    multiplyHigh: 0.35
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00005',
                                name: 'Server (API, Datenbank)',
                                enable: [],
                                calculationInfo: {
                                    addLow: 100,
                                    addHigh: 200,
                                    multiplyLow: 0.25,
                                    multiplyHigh: 0.35
                                },
                                infoText: '',
                                options: []
                            }
                        ],
                        isActive: true,
                        name: 'Ihr Produkt',
                        description: 'Bevor wir den Aufwand verschiedener Aspekte der Entwicklung bestimmen können ist es wichtig zu wissen, um welche Produktart es sich handelt.',
                        type: 'MULTIPLE_SELECT'
                    },
                    // '02': {
                    //     next: '03',
                    //     options: [
                    //         {
                    //             id: '1',
                    //             name: 'App Android',
                    //             enable: [],
                    //             calculationInfo: {
                    //                 addLow: 500,
                    //                 addHigh: 1000,
                    //                 multiplyLow: 0.1,
                    //                 multiplyHigh: 0.2
                    //             },
                    //             infoText: '',
                    //             options: []
                    //         },
                    //         {
                    //             id: '2',
                    //             name: 'App IOS (Apple)',
                    //             enable: [],
                    //             calculationInfo: {
                    //                 addLow: 500,
                    //                 addHigh: 1000,
                    //                 multiplyLow: 0.1,
                    //                 multiplyHigh: 0.2
                    //             },
                    //             infoText: '',
                    //             options: []
                    //         },
                    //         {
                    //             id: '3',
                    //             name: 'Anwendung Windows',
                    //             enable: [],
                    //             calculationInfo: {
                    //                 addLow: 500,
                    //                 addHigh: 1000,
                    //                 multiplyLow: 0.1,
                    //                 multiplyHigh: 0.2
                    //             },
                    //             infoText: '',
                    //             options: []
                    //         },
                    //         {
                    //             id: '4',
                    //             name: 'Browser-App',
                    //             enable: [],
                    //             calculationInfo: {
                    //                 addLow: 200,
                    //                 addHigh: 500,
                    //                 multiplyLow: 0.05,
                    //                 multiplyHigh: 0.1
                    //             },
                    //             infoText: '',
                    //             options: []
                    //         },
                    //     ],
                    //     isActive: true,
                    //     name: 'Ihr System',
                    //     description: 'Neben der Art des Produktes wäre es wichtig zu wissen auf welchen Systemen Ihr Produkt funktionieren soll.',
                    //     type: 'MULTIPLE_SELECT'
                    // },
                    '03': {
                        next: '04',
                        options: [
                            {
                                id: '1',
                                name: 'Smartphone',
                                enable: [],
                                calculationInfo: {
                                    addLow: 250,
                                    addHigh: 400,
                                    multiplyLow: 0.05,
                                    multiplyHigh: 0.1
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '2',
                                name: 'Tablet',
                                enable: [],
                                calculationInfo: {
                                    addLow: 250,
                                    addHigh: 400,
                                    multiplyLow: 0.05,
                                    multiplyHigh: 0.1
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '3',
                                name: 'Desktop',
                                enable: [],
                                calculationInfo: {
                                    addLow: 250,
                                    addHigh: 400,
                                    multiplyLow: 0.05,
                                    multiplyHigh: 0.1
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '4',
                                name: 'Andere',
                                enable: [],
                                calculationInfo: {
                                    addLow: 350,
                                    addHigh: 500,
                                    multiplyLow: 0.05,
                                    multiplyHigh: 0.1
                                },
                                infoText: '',
                                options: []
                            }
                        ],
                        isActive: true,
                        name: 'Ihre gewünschten Geräte',
                        description: 'Auch die Geräte auf denen Ihr System laufen soll, haben einen Einfluss auf den benötigten Aufwand.',
                        type: 'MULTIPLE_SELECT'
                    },
                    '04': {
                        next: '05',
                        options: [
                            {
                                id: '00001',
                                name: 'Consulting Phase: Sie haben eine Idee, jedoch noch kein ausgearbeitetes Konzept',
                                enable: ['07'],
                                calculationInfo: {
                                    addLow: 2500,
                                    addHigh: 3500,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00002',
                                name: 'Design und Prototyp Phase: Sie haben bereits einen Entwurf vorliegen, dieser sollte jedoch noch ausgestaltet werden',
                                enable: ['07'],
                                calculationInfo: {
                                    addLow: 2000,
                                    addHigh: 2500,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00003',
                                name: ' Entwicklungs Phase: Prototyp und Design liegen bereits vor, das Projekt ist bereit entwickelt zu werden',
                                enable: [],
                                calculationInfo: {
                                    addLow: 1000,
                                    addHigh: 1500,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00004',
                                name: 'Supportzeitraum: Das Produkt ist bereits fertig entwickelt, jedoch benötigen Sie Wartung oder Weiterentwicklung',
                                enable: [],
                                calculationInfo: {
                                    addLow: 100,
                                    addHigh: 200,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            }
                        ],
                        isActive: true,
                        name: 'In welcher Phase befindet sich Ihr Projekt derzeit?',
                        description: 'Neben den technischen Anforderungen, wäre es für uns interessant zu wissen wie der derzeitige Projektstand aussieht. Abhängig davon wie viel Vorarbeit in der Entwicklung bereits geleistet wurde, erschließen sich die folgenden Arbeitsschritte und es kann viel Aufwand eingespart werden.',
                        type: 'SINGLE_SELECT'
                    },
                    '05': {
                        next: '06',
                        options: [
                            {
                                id: '1',
                                name: 'Interne App',
                                enable: [],
                                calculationInfo: {
                                    addLow: 500,
                                    addHigh: 700,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '2',
                                name: 'Firmenkunden',
                                enable: [],
                                calculationInfo: {
                                    addLow: 850,
                                    addHigh: 1000,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '3',
                                name: 'Öffentlicher Release',
                                enable: ['14'],
                                calculationInfo: {
                                    addLow: 700,
                                    addHigh: 800,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                        ],
                        isActive: true,
                        name: 'Wer ist Ihre Zielgruppe?',
                        description: 'Ein weiterer wichtiger Aspekt den es nicht nur im Design der App zu beachten gilt, ist die Zielgruppe an den sich ihr Produkt richtet. Soll der geplante Content der Öffentlichkeit zugänglich veröffentlicht werden?',
                        type: 'SINGLE_SELECT'
                    },
                    '06': {
                        next: '07',
                        options: [
                            {
                                id: '01',
                                name: 'Analytics Einbindung',
                                enable: [],
                                calculationInfo: {
                                    addLow: 400,
                                    addHigh: 550,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '02',
                                name: 'Bluetooth',
                                enable: [],
                                calculationInfo: {
                                    addLow: 400,
                                    addHigh: 500,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '03',
                                name: 'Datei Verwaltung',
                                enable: [],
                                calculationInfo: {
                                    addLow: 700,
                                    addHigh: 1000,
                                    multiplyLow: 0.05,
                                    multiplyHigh: 0.05
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '04',
                                name: 'GPS',
                                enable: [],
                                calculationInfo: {
                                    addLow: 350,
                                    addHigh: 450,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '05',
                                name: 'Kamera',
                                enable: [],
                                calculationInfo: {
                                    addLow: 400,
                                    addHigh: 600,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '06',
                                name: 'Maps',
                                enable: [],
                                calculationInfo: {
                                    addLow: 1000,
                                    addHigh: 1350,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '07',
                                name: 'NFC',
                                enable: [],
                                calculationInfo: {
                                    addLow: 400,
                                    addHigh: 500,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '08',
                                name: 'Offline Funktionalität',
                                enable: [],
                                calculationInfo: {
                                    addLow: 1100,
                                    addHigh: 1400,
                                    multiplyLow: 0.05,
                                    multiplyHigh: 0.05
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '09',
                                name: 'PDF/Excel Exporte',
                                enable: [],
                                calculationInfo: {
                                    addLow: 800,
                                    addHigh: 1000,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '10',
                                name: 'Push-Benachrichtigungen',
                                enable: [],
                                calculationInfo: {
                                    addLow: 500,
                                    addHigh: 600,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '11',
                                name: 'QR-/Barcode Scanner',
                                enable: [],
                                calculationInfo: {
                                    addLow: 400,
                                    addHigh: 500,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '12',
                                name: 'Texteditor',
                                enable: [],
                                calculationInfo: {
                                    addLow: 850,
                                    addHigh: 1000,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '13',
                                name: 'Andere',
                                enable: [],
                                calculationInfo: {
                                    addLow: 0,
                                    addHigh: 0,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            }
                        ],
                        isActive: true,
                        name: 'Welche Funktionsanforderungen hat Ihr Produkt?',
                        description: 'Den größten Einfluss auf Aufwand und Preis eines Produktes, haben die vorgesehenen Funktionen. Daher ist es ratsam diese so früh wie möglich festzulegen.',
                        type: 'MULTIPLE_SELECT'
                    },
                    '07': {
                        next: '08',
                        options: [
                            {
                                id: '1',
                                name: 'Wurde bereits ein Konzept erstellt?',
                                enable: [],
                                calculationInfo: {
                                    addLow: 0,
                                    addHigh: 0,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: [
                                    {
                                        id: '331',
                                        name: 'Ja',
                                        enable: [],
                                        calculationInfo: {
                                            addLow: 100,
                                            addHigh: 200,
                                            multiplyLow: 0,
                                            multiplyHigh: 0
                                        },
                                        infoText: '',
                                        options: []
                                    },
                                    {
                                        id: '332',
                                        name: 'Nein',
                                        enable: [],
                                        calculationInfo: {
                                            addLow: 500,
                                            addHigh: 650,
                                            multiplyLow: 0,
                                            multiplyHigh: 0
                                        },
                                        infoText: '',
                                        options: []
                                    },
                                    {
                                        id: '333',
                                        name: 'Keine Angabe',
                                        enable: [],
                                        calculationInfo: {
                                            addLow: 500,
                                            addHigh: 650,
                                            multiplyLow: 0,
                                            multiplyHigh: 0
                                        },
                                        infoText: '',
                                        options: []
                                    },
                                ]
                            },
                            {
                                id: '2',
                                name: 'Sollen wir dieses Konzept für Sie erstellen?',
                                enable: [],
                                calculationInfo: {
                                    addLow: 0,
                                    addHigh: 0,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: [
                                    {
                                        id: '431',
                                        name: 'Ja',
                                        enable: [],
                                        calculationInfo: {
                                            addLow: 1100,
                                            addHigh: 1300,
                                            multiplyLow: 0,
                                            multiplyHigh: 0
                                        },
                                        infoText: '',
                                        options: []
                                    },
                                    {
                                        id: '432',
                                        name: 'Nein',
                                        enable: [],
                                        calculationInfo: {
                                            addLow: 0,
                                            addHigh: 0,
                                            multiplyLow: 0,
                                            multiplyHigh: 0
                                        },
                                        infoText: '',
                                        options: []
                                    },
                                    {
                                        id: '433',
                                        name: 'Keine Angabe',
                                        enable: [],
                                        calculationInfo: {
                                            addLow: 100,
                                            addHigh: 200,
                                            multiplyLow: 0,
                                            multiplyHigh: 0
                                        },
                                        infoText: '',
                                        options: []
                                    },
                                ]
                            },
                            {
                                id: '3',
                                name: 'Gibt es bereits ein Design?',
                                enable: [],
                                calculationInfo: {
                                    addLow: 0,
                                    addHigh: 0,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: [
                                    {
                                        id: '531',
                                        name: 'Ja',
                                        enable: [],
                                        calculationInfo: {
                                            addLow: 0,
                                            addHigh: 0,
                                            multiplyLow: 0,
                                            multiplyHigh: 0
                                        },
                                        infoText: '',
                                        options: []
                                    },
                                    {
                                        id: '532',
                                        name: 'Nein',
                                        enable: [],
                                        calculationInfo: {
                                            addLow: 0,
                                            addHigh: 0,
                                            multiplyLow: 0,
                                            multiplyHigh: 0
                                        },
                                        infoText: '',
                                        options: []
                                    },
                                    {
                                        id: '533',
                                        name: 'Keine Angabe',
                                        enable: [],
                                        calculationInfo: {
                                            addLow: 0,
                                            addHigh: 0,
                                            multiplyLow: 0,
                                            multiplyHigh: 0
                                        },
                                        infoText: '',
                                        options: []
                                    },
                                ]
                            },
                            {
                                id: '4',
                                name: 'Sollen wir dieses Design für Sie erstellen?',
                                enable: [],
                                calculationInfo: {
                                    addLow: 0,
                                    addHigh: 0,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: [
                                    {
                                        id: '631',
                                        name: 'Ja',
                                        enable: ['08'],
                                        calculationInfo: {
                                            addLow: 1000,
                                            addHigh: 1200,
                                            multiplyLow: 0,
                                            multiplyHigh: 0
                                        },
                                        infoText: '',
                                        options: []
                                    },
                                    {
                                        id: '632',
                                        name: 'Nein',
                                        enable: [],
                                        calculationInfo: {
                                            addLow: 0,
                                            addHigh: 0,
                                            multiplyLow: 0,
                                            multiplyHigh: 0
                                        },
                                        infoText: '',
                                        options: []
                                    },
                                    {
                                        id: '633',
                                        name: 'Keine Angabe',
                                        enable: [],
                                        calculationInfo: {
                                            addLow: 0,
                                            addHigh: 0,
                                            multiplyLow: 0,
                                            multiplyHigh: 0
                                        },
                                        infoText: '',
                                        options: []
                                    },
                                ]
                            },
                            {
                                id: '5',
                                name: 'Bestehen ihrerseits Vorgaben betreffend einer CI (Corporate Identity)?',
                                enable: [],
                                calculationInfo: {
                                    addLow: 0,
                                    addHigh: 0,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: [
                                    {
                                        id: '731',
                                        name: 'Ja',
                                        enable: [],
                                        calculationInfo: {
                                            addLow: 0,
                                            addHigh: 0,
                                            multiplyLow: 0,
                                            multiplyHigh: 0
                                        },
                                        infoText: '',
                                        options: []
                                    },
                                    {
                                        id: '732',
                                        name: 'Nein',
                                        enable: [],
                                        calculationInfo: {
                                            addLow: 0,
                                            addHigh: 0,
                                            multiplyLow: 0,
                                            multiplyHigh: 0
                                        },
                                        infoText: '',
                                        options: []
                                    },
                                    {
                                        id: '733',
                                        name: 'Keine Angabe',
                                        enable: [],
                                        calculationInfo: {
                                            addLow: 0,
                                            addHigh: 0,
                                            multiplyLow: 0,
                                            multiplyHigh: 0
                                        },
                                        infoText: '',
                                        options: []
                                    },
                                ]
                            }
                        ],
                        isActive: false,
                        name: 'Vorausplanung Design',
                        description: 'Für unser Designteam ist es besonders interessant zu wissen auf welchen Informationen und Hintergründen Ihr Designkonzept erstellt werden soll. Wenn beispielsweise schon Ideen, Konzepte und Vorgaben existieren, beschleunigt das den Designprozess!',
                        type: 'QUESTION'
                    },
                    '08': {
                        next: '09',
                        options: [
                            {
                                id: '00001',
                                name: 'Schlichtes Design: Ein Farbschema, einige Icons oder eigene Buttons werden benötigt',
                                enable: [],
                                calculationInfo: {
                                    addLow: 500,
                                    addHigh: 600,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00002',
                                name: 'Personalisiertes Design',
                                enable: [],
                                calculationInfo: {
                                    addLow: 700,
                                    addHigh: 850,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00003',
                                name: 'Komplexes Design: Farben, Logos, Grafiken, Animationen oder andere Aspekte der Gestaltung sollen eingebaut werden',
                                enable: [],
                                calculationInfo: {
                                    addLow: 1000,
                                    addHigh: 1200,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00004',
                                name: 'Die Gestaltung der Oberfläche ist für das Produkt irellevant',
                                enable: [],
                                calculationInfo: {
                                    addLow: 0,
                                    addHigh: 0,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            }
                        ],
                        isActive: false,
                        name: 'Wie umfangreich gestaltet stellen Sie sich Ihr Produkt vor?',
                        description: 'Ein Design sind nicht einfach ein Paar zusammengeworfene Farben. Je ausgefallener und vielfältiger die Gestaltung ihres Produktes sein soll, desto aufwendiger ist auch die Arbeit die in Ihr Design gesteckt werden muss.',
                        type: 'SINGLE_SELECT'
                    },
                    '09': {
                        next: '10',
                        options: [
                            {
                                id: '00001',
                                name: 'Ja',
                                enable: ['10'],
                                calculationInfo: {
                                    addLow: 2000,
                                    addHigh: 2500,
                                    multiplyLow: 0.15,
                                    multiplyHigh: 0.2
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00002',
                                name: 'Nein',
                                enable: [],
                                calculationInfo: {
                                    addLow: 0,
                                    addHigh: 0,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00003',
                                name: 'Keine Angabe',
                                enable: [],
                                calculationInfo: {
                                    addLow: 0,
                                    addHigh: 0,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            }
                        ],
                        isActive: true,
                        name: 'Haben Sie ein Backoffice System für Ihr Produkt geplant?',
                        description: 'Ein Backoffice System beschreibt eine offen zugängliche Oberfläche wie eine Website, die von einem zweiten, administrierenden Content Management System aus, verwaltet werden kann.',
                        type: 'SINGLE_SELECT'
                    },
                    '10': {
                        next: '11',
                        options: [
                            {
                                id: '00001',
                                name: 'App Inhalte (Texte, Bilder, ...)',
                                enable: [],
                                calculationInfo: {
                                    addLow: 800,
                                    addHigh: 1000,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00002',
                                name: 'Auswertungen/Diagramme',
                                enable: [],
                                calculationInfo: {
                                    addLow: 1000,
                                    addHigh: 1200,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00003',
                                name: 'Buchungen-, Transaktions Verläufe',
                                enable: [],
                                calculationInfo: {
                                    addLow: 600,
                                    addHigh: 800,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00004',
                                name: 'Geplante Push-Benachrichtigungen',
                                enable: [],
                                calculationInfo: {
                                    addLow: 500,
                                    addHigh: 600,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00005',
                                name: 'Nutzerverwaltung',
                                enable: [],
                                calculationInfo: {
                                    addLow: 700,
                                    addHigh: 850,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00006',
                                name: 'Rechtemanagement',
                                enable: [],
                                calculationInfo: {
                                    addLow: 900,
                                    addHigh: 1000,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00007',
                                name: 'Support Tickets',
                                enable: [],
                                calculationInfo: {
                                    addLow: 900,
                                    addHigh: 1000,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00008',
                                name: 'Verwaltung von Produkten, Geräten, ...',
                                enable: [],
                                calculationInfo: {
                                    addLow: 900,
                                    addHigh: 1000,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00009',
                                name: 'Andere',
                                enable: [],
                                calculationInfo: {
                                    addLow: 0,
                                    addHigh: 0,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            }
                        ],
                        isActive: false,
                        name: 'Welche Inhalte soll es in diesem Backoffice System geben?',
                        description: 'Damit wir den Umfang Ihres Backoffice Systems besser verstehen können, wählen sie bitte aus was sie im System verwalten wollen:',
                        type: 'MULTIPLE_SELECT'
                    },
                    '11': {
                        next: '12',
                        options: [
                            {
                                id: '00001',
                                name: 'Email Login',
                                enable: [],
                                calculationInfo: {
                                    addLow: 600,
                                    addHigh: 700,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00002',
                                name: 'Social Media Login',
                                enable: [],
                                calculationInfo: {
                                    addLow: 900,
                                    addHigh: 1000,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00003',
                                name: 'Kein Login geplant',
                                enable: [],
                                calculationInfo: {
                                    addLow: 0,
                                    addHigh: 0,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            }
                        ],
                        isActive: true,
                        name: 'Ist ein Login oder Profilsystem geplant?',
                        description: 'Diese Funktion hat insbesondere auf die technische Konzeption ihres Produktes einen direkten Einfluss.',
                        type: 'MULTIPLE_SELECT'
                    },
                    '12': {
                        next: '13',
                        options: [
                            {
                                id: '00001',
                                name: 'Ich liefere die Inhalte',
                                enable: [],
                                calculationInfo: {
                                    addLow: 150,
                                    addHigh: 250,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00002',
                                name: 'Ihr erstellt die Inhalte',
                                enable: [],
                                calculationInfo: {
                                    addLow: 1200,
                                    addHigh: 1500,
                                    multiplyLow: 0.05,
                                    multiplyHigh: 0.1
                                },
                                infoText: '',
                                options: []
                            },
                        ],
                        isActive: true,
                        name: 'Wer ist für die Inhalte in Ihrem Produkt verantwortlich?',
                        description: 'Mit Inhalten sind Texte, Bilder und Grafiken gemeint.',
                        type: 'SINGLE_SELECT'
                    },
                    '13': {
                        next: '14',
                        options: [
                            {
                                id: '00001',
                                name: 'Einsprachig',
                                enable: [],
                                calculationInfo: {
                                    addLow: 0,
                                    addHigh: 0,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00002',
                                name: 'Mehrsprachig',
                                enable: [],
                                calculationInfo: {
                                    addLow: 800,
                                    addHigh: 1500,
                                    multiplyLow: 0.05,
                                    multiplyHigh: 0.15
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00003',
                                name: 'Noch unklar',
                                enable: [],
                                calculationInfo: {
                                    addLow: 0,
                                    addHigh: 0,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                        ],
                        isActive: true,
                        name: 'Soll Ihr Projekt Ein- oder Mehrsprachig entwickelt werden?',
                        description: 'Natürlich ist Mehrsprachigkeit in der Entwicklung ein wenig aufwendiger, solange jedoch dieser Aufwand von vornherein eingeplant ist, kann schon in der Planung, Entwicklungszeit gespaart werden.',
                        type: 'SINGLE_SELECT'
                    },
                    '14': {
                        next: null,
                        options: [
                            {
                                id: '00001',
                                name: 'Kostenlose App',
                                enable: [],
                                calculationInfo: {
                                    addLow: 0,
                                    addHigh: 0,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00002',
                                name: 'App mit Werbeschaltung',
                                enable: [],
                                calculationInfo: {
                                    addLow: 1000,
                                    addHigh: 1200,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00003',
                                name: 'Einmalkauf',
                                enable: [],
                                calculationInfo: {
                                    addLow: 150,
                                    addHigh: 250,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00004',
                                name: 'Abonnement',
                                enable: [],
                                calculationInfo: {
                                    addLow: 750,
                                    addHigh: 900,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00005',
                                name: 'In App Käufe',
                                enable: [],
                                calculationInfo: {
                                    addLow: 1200,
                                    addHigh: 1500,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            },
                            {
                                id: '00006',
                                name: 'Keine Bezahloption',
                                enable: [],
                                calculationInfo: {
                                    addLow: 0,
                                    addHigh: 0,
                                    multiplyLow: 0,
                                    multiplyHigh: 0
                                },
                                infoText: '',
                                options: []
                            }
                        ],
                        isActive: false,
                        name: 'Welches Bezahlmodell haben Sie für Ihr Projekt geplant?',
                        description: 'Falls Sie ein öffentliches Produkt anstreben, haben Sie sich schon Gedanken um Bezahlmethoden gemacht?',
                        type: 'MULTIPLE_SELECT'
                    },
                },
                startBudgetHigh: 200000,
                startBudgetLow: 0,
            },
            budget: 0,
            answers: {} as Answer,
            contactInfo: new ContactInfo(),
            calcBudgetLow: 0,
            calcBudgetHigh: 0,
            calcBudgetAddLow: 0,
            calcBudgetAddHigh: 0,
            calcBudgetMultiplyLow: 0,
            calcBudgetMultiplyHigh: 0,
            userId: "82737123"
        } as PiniaStore

        const localId = ''
        const source = ''
        const userAgent = ''

        const history = {} as StoreHistory

        // const activation: NodeId[] = []

        return {
            // all these properties will have their type inferred automatically
            data,
            sliderPage,
            endPage,
            homePage,
            history,
            localId,
            source,
            userAgent,
        }
    },
    actions: {
        async generateLocalId(): Promise<string> {
            const localKey = 'localId'
            const { value } = await Preferences.get({ key: localKey });

            if (value) {
                this.localId = value
            } else {
                this.localId = uuid4()
            }

            await Preferences.set({
                key: localKey,
                value: this.localId,
            });

            return this.localId
        },
        async captureSource(src: string) {
            const sourceKey = 'source'
            this.source = src

            await Preferences.set({
                key: sourceKey,
                value: this.source,
            });
        },
        getUserAgent(): string {
            this.userAgent = navigator.userAgent
            return this.userAgent
        }
    }
})
