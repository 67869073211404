
import { defineComponent } from "vue";
import { IonButton } from "@ionic/vue";

export default defineComponent({
    name: "BaseButton",
    components: {
        IonButton,
    },
    props: {
        noShadow: Boolean,
        color: String,
        nextButton: Boolean,
        singleSelect: Boolean,
        showInfo: String,
    }
})
