
import { defineComponent, onMounted, ref } from "vue";
import { IonCol, IonRow } from "@ionic/vue";
import { useStore } from "@/store/pinia";
import BudgetField from "@/components/BudgetField.vue";
import ContactForm from "@/components/slider/ContactForm.vue";
import router from "@/router";
import { initPiniaStore } from "@/composables/initStore";
import { State } from "@/types/typeDefinitions";
import { useApi } from "@/composables/api";

export default defineComponent({
    name: "EndPage",
    components: {
        BudgetField,
        IonRow,
        IonCol,
        ContactForm
    },
    setup() {
        const store = useStore()
        const { sendHistory } = useApi()
        const commitSent = ref(false)

        onMounted(() => {
            sendHistory(State.end)
        })

        const backToStart = async () => {
            //reset store
            store.history = {}
            await initPiniaStore()
            await router.push({ name: 'Home' })
        }

        return {
            backToStart,
            store,
            commitSent,
        }
    }
})
