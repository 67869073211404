
import { computed, defineComponent, getCurrentInstance } from "vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import { IonCol, IonProgressBar, IonRow } from "@ionic/vue";
import { useStore } from "@/store/pinia";
import router from "@/router";

export default defineComponent({
    name: "NodePagination",
    components: {
        BaseButton,
        // IonGrid,
        IonRow,
        IonCol,
        IonProgressBar,
    },
    props: {
        isNode: Boolean,
        nextActive: Boolean,
    },
    emits: ["second-page", "next", "nextInactive"],
    setup() {
        const store = useStore()
        const {emit, ...context} = getCurrentInstance() as NonNullable<ReturnType<typeof getCurrentInstance>>;
        const progress = computed(() => {
            const nodes = store.data.config.nodes
            let count = 0

            for(const node in nodes) {
                if(nodes[node].isActive) {
                    count+=1
                }
            }
            const perStep = 1/count

            return Object.keys(store.history).length * perStep
        })

        const goBack = () => {
            delete store.history[router.currentRoute.value.params.nodeid as string]
            emit.call(context, 'nextInactive')
            router.back()
        }

        return {
            goBack,
            progress
        }
    }
})
