
import { IonCol, IonContent, IonGrid, IonPage, IonRow, onIonViewWillEnter } from '@ionic/vue';
import { defineComponent, reactive, ref } from 'vue';
import NodeHeadline from '../components/node/NodeHeadline.vue';
import NodeDescription from '../components/node/NodeDescription.vue';
import NodeContent from '../components/node/NodeContent.vue';
import NodePagination from '../components/node/NodePagination.vue';
import { Node, NodeId, State } from "@/types/typeDefinitions";
import { useStore } from "@/store/pinia";
import router from "@/router";
import { nodeHandling } from "@/composables/nodeHandling";
import { useApi } from "@/composables/api";

export default defineComponent({
    name: 'HomePage',
    components: {
        IonContent,
        IonPage,
        IonGrid,
        IonRow,
        IonCol,
        NodeHeadline,
        NodeDescription,
        NodeContent,
        NodePagination,
    },
    setup() {
        const store = useStore()
        const node = reactive<Node>({} as Node)
        const isNode = ref(true)
        const { enableNodes } = nodeHandling()
        const isLoading = ref(true)
        const { sendHistory } = useApi()
        const nextActive = ref(false)

        const update = () => {
            isLoading.value = true

            const nodeId = router.currentRoute.value.params.nodeid as string
            if (nodeId) {
                loadNode(nodeId)
                // //if node not active return next node
                // if (!store.data.config.nodes[nodeId].isActive) return nextNode()
            } else if (router.currentRoute.value.name === 'Slider') {
                Object.assign(node, store.sliderPage)
            } else if (router.currentRoute.value.name === 'Home') {
                Object.assign(node, store.homePage)
                isNode.value = false
            } else if (router.currentRoute.value.name === 'EndPage') {
                Object.assign(node, store.endPage)
            }
            isLoading.value = false
        }

        onIonViewWillEnter(() => {
            update()
            nextActive.value = false
        })

        const openSecond = () => {
            sendHistory(State.start)
            router.push({ name: 'Slider' })
        }


        const loadNode = (nodeId: string) => {
            //get state of piniastore from history - set data
            store.data = JSON.parse(JSON.stringify(store.history[nodeId]))
            const newNode = store.data.config.nodes[nodeId]
            Object.assign(node, newNode)
        }

        const nextNode = async () => {
            let nextNode: NodeId = ''
            if (router.currentRoute.value.name === 'Slider') {
                nextNode = store.data.config.startNode

                store.history[nextNode] = JSON.parse(JSON.stringify(store.data))
                await sendHistory(State.budget)
                await router.push({ name: 'Node', params: { nodeid: nextNode } })
            } else {
                const nodeId = router.currentRoute.value.params.nodeid as string
                await sendHistory()
                await enableNodes(nodeId)

                //find next node
                nextNode = store.data.config.nodes[nodeId].next ?? ''
                while (!store.data.config.nodes[nextNode]?.isActive) {
                    if (!nextNode) return router.push({ name: 'EndPage' })
                    nextNode = store.data.config.nodes[nextNode].next ?? ''
                }

                store.history[nextNode] = JSON.parse(JSON.stringify(store.data))
                await router.push({ name: 'Node', params: { nodeid: nextNode } })
            }
        }
        return {
            isNode,
            openSecond,
            nextNode,
            node,
            store,
            isLoading,
            nextActive,
        }
    }
});
