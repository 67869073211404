import { Option } from "@/types/typeDefinitions";
import { useStore } from "@/store/pinia";

export function useCalculation() {

    const store = useStore()


    const calcBudget = (newItem?: Option, oldItem?: Option) => {
        if(oldItem) {
            const oldCalcInfo = oldItem.calculationInfo// getCalcInfo(oldItem)
            console.log("oldcalcinfo", oldCalcInfo)
            if(oldCalcInfo) {
                store.data.calcBudgetAddLow -= oldCalcInfo.addLow
                store.data.calcBudgetAddHigh -= oldCalcInfo.addHigh
                store.data.calcBudgetMultiplyLow -= oldCalcInfo.multiplyLow
                store.data.calcBudgetMultiplyHigh -= oldCalcInfo.multiplyHigh
            }
        }
        //add new value
        console.log("newItem", newItem)
        if(newItem) {
            const newCalcInfo = newItem.calculationInfo//getCalcInfo(newItem)
            console.log("newcalcinfo", newCalcInfo)
            if (newCalcInfo) {
                store.data.calcBudgetAddLow += newCalcInfo.addLow
                store.data.calcBudgetAddHigh += newCalcInfo.addHigh
                store.data.calcBudgetMultiplyLow += newCalcInfo.multiplyLow
                store.data.calcBudgetMultiplyHigh += newCalcInfo.multiplyHigh
            }
        }
        store.data.calcBudgetLow = Math.round(store.data.calcBudgetAddLow * (store.data.calcBudgetMultiplyLow + 1)/100)*100
        store.data.calcBudgetHigh = Math.round(store.data.calcBudgetAddHigh * (store.data.calcBudgetMultiplyHigh + 1)/100)*100
    }


    return {
        calcBudget,
    }
}
