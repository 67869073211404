
import { IonApp, IonPage, IonRouterOutlet } from '@ionic/vue';
import { defineComponent, onMounted } from 'vue';
import CustomHeader from './components/CustomHeader.vue';
import { useStore } from '@/store/pinia';
import { State } from '@/types/typeDefinitions';
import { useApi } from '@/composables/api';
import { useRoute } from 'vue-router';

export default defineComponent({
    name: 'App',
    components: {
        IonApp,
        IonRouterOutlet,
        IonPage,
        CustomHeader,
    },
    setup() {
        const store = useStore()
        const { sendHistory } = useApi()
        const route = useRoute()

        onMounted(async () => {
            await store.generateLocalId()
            await store.captureSource(typeof route.query.src === 'string' ? route.query.src : '')
            store.getUserAgent()
            await sendHistory(State.init)
        })

        return {}
    }
});
