
import { defineComponent, getCurrentInstance, reactive } from "vue";
import { IonCol, IonIcon, IonRow, popoverController } from "@ionic/vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import { useCalculation } from "@/composables/calculation";
import { Option } from "@/types/typeDefinitions";
import { nodeHandling } from "@/composables/nodeHandling";
import { helpCircleOutline } from "ionicons/icons";
import InfoPopup from "@/components/popover/InfoPopup.vue";

export default defineComponent({
    name: "MultipleSelect",
    props: {
        items: {
            required: true,
            type: Array
        }
    },
    components: {
        BaseButton,
        IonCol,
        IonIcon,
        IonRow,
    },
    emits: ["nextActive", "nextInactive"],
    setup() {
        const itemsSelected = reactive<Option[]>([])
        const { calcBudget } = useCalculation()
        const { saveAnswers, deleteAnswers } = nodeHandling()
        const { emit, ...context } = getCurrentInstance() as NonNullable<ReturnType<typeof getCurrentInstance>>;

        const selectItem = (item: Option) => {
            if (itemsSelected.includes(item)) {
                calcBudget(undefined, item)
                const itemIndex = itemsSelected.indexOf(item)
                itemsSelected.splice(itemIndex, 1)
                deleteAnswers(item)
            } else {
                calcBudget(item)
                itemsSelected.push(item)
                saveAnswers(item)
            }

            if (itemsSelected.length) {
                emit.call(context, 'nextActive')
            } else {
                emit.call(context, 'nextInactive')
            }
        }

        const showPopover = async (ev: Event, text: string) => {
            const popover = await popoverController.create({
                component: InfoPopup,
                componentProps: { infoText: text },
                event: ev,
                translucent: false,
                showBackdrop: false,
            })
            await popover.present()
        }

        return {
            showPopover,
            selectItem,
            itemsSelected,
            helpCircleOutline
        }
    }
})
