
import { defineComponent } from "vue";
import { useStore } from "@/store/pinia";
import { IonAccordion, IonAccordionGroup, IonCol, IonItem, IonLabel, IonRow } from "@ionic/vue";

export default defineComponent({
    name: "BudgetBig",
    components: {
        IonCol,
        IonRow,
        IonAccordionGroup,
        IonAccordion,
        IonItem,
    },
    setup() {
        return {
            store: useStore()
        }
    }
})
