
import { defineComponent, getCurrentInstance, reactive } from "vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import { IonCol, IonItem, IonList, IonRow } from "@ionic/vue";
import { useCalculation } from "@/composables/calculation";
import { Option } from "@/types/typeDefinitions";
import { nodeHandling } from "@/composables/nodeHandling";

export default defineComponent({
    name: "SingleSelect",
    components: {
        BaseButton,
        IonList,
        IonItem,
        IonCol,
        IonRow
    },
    props: {
        items: {
            require: true,
            type: Array
        },
        nextActive: Boolean
    },
    emits: ["nextActive"],
    setup(props) {
        const itemSelected = reactive<Option>({} as Option)
        const { calcBudget } = useCalculation()
        const { saveAnswers, deleteAnswers } = nodeHandling()
        const { emit, ...context } = getCurrentInstance() as NonNullable<ReturnType<typeof getCurrentInstance>>;

        const selectItem = (item: Option) => {
            if (itemSelected.id) deleteAnswers(itemSelected)
            saveAnswers(item)

            //calc new budget (auslagern in ts)
            console.log("calcbudget")
            if (props.nextActive) {
                calcBudget(item, itemSelected)
            } else {
                calcBudget(item)
            }

            Object.assign(itemSelected, item)

            emit.call(context, 'nextActive')
        }

        return {
            selectItem,
            itemSelected,
        }
    }
})
