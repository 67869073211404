
import { defineComponent } from "vue";

export default defineComponent({
    name: "InfoPopup",
    props: {
        infoText: String
    }

})
