import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_slider_node = _resolveComponent("slider-node")!
  const _component_multiple_select = _resolveComponent("multiple-select")!
  const _component_question_select = _resolveComponent("question-select")!
  const _component_single_select = _resolveComponent("single-select")!
  const _component_end_page = _resolveComponent("end-page")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.nodeType === _ctx.NODE_TYPE.SLIDER)
      ? (_openBlock(), _createBlock(_component_slider_node, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.nodeType === _ctx.NODE_TYPE.MULTIPLE_SELECT)
      ? (_openBlock(), _createBlock(_component_multiple_select, {
          key: 1,
          onNextActive: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('nextActive'))),
          onNextInactive: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('nextInactive'))),
          items: _ctx.items
        }, null, 8, ["items"]))
      : _createCommentVNode("", true),
    (_ctx.nodeType === _ctx.NODE_TYPE.QUESTION)
      ? (_openBlock(), _createBlock(_component_question_select, {
          key: 2,
          onNextActive: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('nextActive'))),
          items: _ctx.items
        }, null, 8, ["items"]))
      : _createCommentVNode("", true),
    (_ctx.nodeType === _ctx.NODE_TYPE.SINGLE_SELECT)
      ? (_openBlock(), _createBlock(_component_single_select, {
          key: 3,
          "next-active": _ctx.nextActive,
          onNextActive: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('nextActive'))),
          items: _ctx.items
        }, null, 8, ["next-active", "items"]))
      : _createCommentVNode("", true),
    (_ctx.nodeType === _ctx.NODE_TYPE.END_PAGE)
      ? (_openBlock(), _createBlock(_component_end_page, { key: 4 }))
      : _createCommentVNode("", true)
  ], 64))
}